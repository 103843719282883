/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-30 18:37:39
 * @Module: login 小模块(登陆后用户的模块)
 */
<template>
  <div>
    <div class="login-user-box"
         v-loading="loading"
         v-if="userInfo">
      <div class="login-user-avatar"
           style="position:relative;"
           @click="$store.dispatch('homepage/toPersonal', { uuid: userInfo.uuid })">
        <el-avatar :size="58"
                   :src="userInfo.avatar"
                   class="hover"></el-avatar>
        <img src="@/static/img/renzheng.gif"
             alt=""
             v-if="!(userInfo.user_verify == null)"
             style="width:12px;height:12px;position:absolute;bottom:0px;right:43%;z-index:100" />
      </div>
      <div class="login-user-name"
           style="margin-top:8px">
        <span :class="userInfo.is_vip ? 'new-color' : ''">{{
          userInfo.nick_name
        }}</span>
      </div>
      <div class="login-user-info hover">
        <div class="login-user-info-item"
             @click="
            $store.dispatch('homepage/toPersonal', { uuid: userInfo.uuid })
          ">
          <div class="login-user-info-item-t">
            {{ userInfo.published_count }}
          </div>
          <div class="login-user-info-item-b">发布</div>
        </div>
        <div class="login-user-info-solid1" />
        <div class="login-user-info-item"
             @click="
            $store.dispatch('homepage/toFollowe', {
              uuid: userInfo.uuid,
              type: 'followed'
            })
          ">
          <div class="login-user-info-item-t">{{ userInfo.following }}</div>
          <div class="login-user-info-item-b">关注</div>
        </div>
        <div class="login-user-info-solid2" />
        <div class="login-user-info-item"
             @click="
            $store.dispatch('homepage/toFollowe', {
              uuid: userInfo.uuid,
              type: 'follower'
            })
          ">
          <div class="login-user-info-item-t">{{ userInfo.follower }}</div>
          <div class="login-user-info-item-b">粉丝</div>
        </div>
      </div>
      <div class="login-user-btn hover"
           @click="$router.push('/homepage/publish')">
        发布
      </div>
    </div>

    <div class="loginmodule-box"
         v-else>
      <div class="loginmodule-nav">
        <div v-for="item in activeList"
             :key="item.key"
             class="hover"
             :class="
            active == item.key ? 'loginmodule-nav-ac' : 'loginmodule-nav-df'
          "
             @click="click(item.key)">
          {{ item.title }}
        </div>
      </div>
      <transition name="el-fade-in-linear">
        <div v-show="active == 'phone'">
          <el-input v-model="phone"
                    placeholder="手机号码、仅支持大陆手机号码"
                    style="margin-top:16px"></el-input>
          <div style="margin-top:12px;display: flex;">
            <el-input v-model="verify_code"
                      style="width:180px"
                      placeholder="请输入验证码"
                      @keyup.enter.native="_reqLogin"></el-input>
            <div class="loginmodule-getcode-btn hover"
                 @click="_reqVerifyCode">
              {{ secondrender }}
            </div>
          </div>
          <div class="loginmodule-btn hover"
               @click="_reqLogin">立即登录</div>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <qrcode-vue :value="qrcodevalue"
                    v-show="active == 'QRcode'"
                    class="loginmodule-qrcode"
                    :size="154"
                    v-loading="confirmCodeLoading"
                    @click.native="_confirmCode"
                    level="H"></qrcode-vue>
      </transition>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import loginmixins from "./loginmixins.js";
import userInfo from "@/mixins/userinfo";
export default {
  components: {
    QrcodeVue
  },
  mixins: [loginmixins, userInfo],
  data () {
    return {
      active: "phone",
      activeList: [
        {
          title: "验证码登录",
          key: "phone"
        },
        {
          title: "扫码登陆",
          key: "QRcode"
        }
      ]
    };
  },
  mounted () {
    console.log(this.userInfo);
  },
  computed: {
    loginVisible: {
      get: function () {
        return this.$store.state.layouts.loginVisible
      },
      set: function (res) {
        this.$store.commit('layouts/changeLoginVisible', res)
      }
    }
  },
  methods: {
    click (active) {
      if (active == "QRcode") {
        this._confirmCode()
      }
      this.active = active;
    },
    loginSuccess () {
      console.log("mod成功");
    }
  },
  watch: {
    loginVisible: {
      handler: function (val) {
        if (val) {
          this.active = 'phone'
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
</script>
<style lang='scss' scoped>
.login-user-box,
.loginmodule-box {
  width: 100%;
  height: 244px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.login-user {
  &-avatar {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  &-name {
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
    color: #333333;
  }
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    height: 40px;
    align-items: center;
    position: relative;
    &-solid1,
    &-solid2 {
      width: 1px;
      height: 24px;
      background: #eeeeee;
      position: absolute;
    }
    &-solid1 {
      left: 112px;
    }
    &-solid2 {
      right: 112px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      &-b {
        font-size: 14px;
        color: #999;
        margin-top: 3px;
      }
    }
  }
  &-btn {
    margin: 0px 20px;
    margin-top: 20px;
    width: 300px !important;
  }
}
.loginmodule-box {
  padding: 10px 22px 20px;
  position: relative;
}
.loginmodule-nav {
  display: flex;
}
.loginmodule-nav-df,
.loginmodule-nav-ac {
  width: 50%;
  text-align: center;
  line-height: 44px;
}
.loginmodule-nav-df {
  border-bottom: solid 1px #eee;
  color: #999999;
}
.loginmodule-nav-ac {
  border-bottom: solid 2px #ff0000;
  color: #333;
}
.loginmodule-getcode-btn {
  width: 110px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
}
.loginmodule-btn,
.login-user-btn {
  line-height: 40px;
  background: #ff0000;
  border-radius: 4px;
  margin-top: 20px;
  color: #fff;
  width: 64px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC;
  color: #ffffff;
  width: 100%;
  font-weight: 500;
}
.loginmodule-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100%;
  position: absolute;
  bottom: 18px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.new-color {
  color: #df2a29 !important;
}
</style>